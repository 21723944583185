import React from 'react'
import SEO from '../components/seo'
import Header from '../components/home/header'
import Newsletter from '../components/home/newsletter'
import Featured from '../components/home/featured'
import Recent from '../components/home/recent'
import Footer from '../components/home/footer'
import './index.css'

const IndexPage = () => {
  return (
    <div className="home-wrapper">
      <SEO
        title="Blog"
        description="Develop and Go is a blog where developers can learn how to develop an API, mobile applications, and web applications with real world code example."
        url={`https://developandgo.com`}
        lang="en"
        keywords={[
          'blog',
          'develop and go',
          'HTML',
          'CSS',
          'XML',
          'JavaScript',
          'ASU',
          'Arizona State University',
          'MySQL',
          'PostgreSQL',
          'CSS3',
          'Bootstrap',
          'ES6',
          'React',
          'AWS',
          'API Gateway',
          'Cognito',
          'Lambda',
          'S3',
          'Serverless',
          'GraphQL',
          'NodeJS',
          'PHP',
          'RESTful',
          'Java',
          'Swift',
          'React Native',
          'PetExec',
          'PetExec Mobile',
          'PetExec API',
          'Top Dog Daycare',
          'Tempe, AZ',
          'Arizona',
        ]}
      />
      <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.6.3/css/all.css"
        integrity="sha384-LRlmVvLKVApDVGuspQFnRQJjkv0P7/YFrw84YYQtmYG4nK8c+M+NlmYDCv0rKWpG"
        crossOrigin="anonymous"
      />
      <Header />
      <Newsletter />
      <Featured />
      <Recent />
      <Footer />
    </div>
  )
}

export default IndexPage
