import React, { Component } from 'react'
import './header.css'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <header>
        <div id="logo">
          <h1>Develop and Go</h1>
          <hr className="brace" />
        </div>
        <div id="headline">
          <h2>
            <strong className="red">A</strong>PI x{' '}
            <strong className="red">A</strong>WS x{' '}
            <strong className="red">D</strong>atabases x{' '}
            <strong className="red">R</strong>eact x{' '}
            <strong className="red">S</strong>erverless
          </h2>
        </div>
      </header>
    )
  }
}

export default Header
