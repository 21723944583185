import React, { Component } from 'react'
import './newsletter.css'

class Newsletter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      email: '',
      showError: false,
      showSuccess: false,
      message: '',
    }
  }

  async subscribe() {
    this.setState({
      loading: true,
      showError: false,
    })

    const subscribe = await fetch(
      'https://1rgr6ewxve.execute-api.us-east-1.amazonaws.com/prod/subscribe',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `9RriJhm1kS4OpP3uUXWBB0430ICQDuiBiNW3mpf0`,
        },
        body: JSON.stringify({
          email: this.state.email,
        }),
      }
    )

    const json = await subscribe.json()
    const { success, message } = json

    if (success) {
      this.setState({
        loading: false,
        showError: false,
        showSuccess: true,
        email: '',
        message: 'Thank you 😃.',
      })
    } else {
      this.setState({
        loading: false,
        showError: true,
        showSuccess: false,
        message,
      })
    }
  }

  render() {
    return (
      <section id="newsletter">
        <div id="newsletter__form">
          <h4 className="red">JOIN THE COMMUNITY!</h4>

          <form id="form">
            {this.state.showSuccess ? (
              <div
                className="newsletter__error alert alert-success text-center mt-3"
                role="alert"
              >
                <strong>{this.state.message}</strong>
              </div>
            ) : (
              <>
                <input
                  id="newsletter__input"
                  type="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={email =>
                    this.setState({ email: email.target.value })
                  }
                />

                <div id="newsletter__button">
                  {this.state.loading ? (
                    <div className="spinner" />
                  ) : (
                    <button
                      type="button"
                      className="btn-primary"
                      onClick={() => this.subscribe()}
                    >
                      JOIN
                    </button>
                  )}
                </div>
              </>
            )}
          </form>
        </div>
        {this.state.showError && (
          <div
            className="newsletter__error alert alert-danger text-center mt-3"
            role="alert"
          >
            <strong>{this.state.message}</strong>
          </div>
        )}
      </section>
    )
  }
}

export default Newsletter
