import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import './featured.css'
import Post from '../../post'

class Featured extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <section className="featured">
        <div className="inner">
          <h2 className="center">Featured</h2>
          <StaticQuery
            query={featuredQuery}
            render={data => {
              return (
                <>
                  {data.allMarkdownRemark.edges.map(({ node }) => (
                    <Post
                      key={node.id}
                      title={node.frontmatter.title}
                      author={node.frontmatter.author}
                      slug={node.fields.slug}
                      date={node.frontmatter.date}
                      body={node.excerpt}
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                      tags={node.frontmatter.tags}
                    />
                  ))}
                </>
              )
            }}
          />
        </div>
      </section>
    )
  }
}

const featuredQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { featured: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMM Do YYYY")
            author
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default Featured
