import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Post = ({ title, author, slug, date, body, fluid, tags }) => {
  return (
    <div className="card">
      <div className="card-img">
        <Link to={slug}>
          <Img fluid={fluid} />
        </Link>
      </div>
      <div className="card-body">
        <h4 className="card-title">
          <Link
            className="font-weight-bold"
            style={{ color: '#573e97' }}
            to={slug}
          >
            {title}
          </Link>
        </h4>

        <h6 className="card-subtitle text-muted">
          <span className="text-info">{date}</span> by
          <span className="text-info"> {author}</span>
        </h6>

        <p>{body}</p>
      </div>
      <div className="card-footer">
        <Link to={slug}>
          <button type="button" className="btn-primary">
            Read more
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Post
