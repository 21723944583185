import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Post from '../../post'
// import Pagination from '../../pagination';
import './recent.css'

class Recent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const postsPerPage = 5
    let numberOfPages
    return (
      <section className="recent">
        <h2 className="center">Recent Posts</h2>

        <StaticQuery
          query={recentQuery}
          render={data => {
            numberOfPages = Math.ceil(
              data.allMarkdownRemark.totalCount / postsPerPage
            )
            return (
              <div className="posts">
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  <div className="post">
                    <Post
                      key={node.id}
                      title={node.frontmatter.title}
                      author={node.frontmatter.author}
                      slug={node.fields.slug}
                      date={node.frontmatter.date}
                      body={node.excerpt}
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                      tags={node.frontmatter.tags}
                    />
                  </div>
                ))}
                {/* <Pagination currentPage={1} numberOfPages={numberOfPages} /> */}
              </div>
            )
          }}
        />
      </section>
    )
  }
}

const recentQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMM Do YYYY")
            author
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default Recent
