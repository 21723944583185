import React, { Component } from 'react'
import './footer.css'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <footer>{/* footer */}</footer>
  }
}

export default Footer
